import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const ContainerWrapper = styled.form`
  margin: auto;
  justify-content: center;
`;

export const IdentificationBox = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #000;
  border-radius: 5px;
`;

export const Input = styled.input`
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0px 10px 0px;
`;

export const Select = styled.select`
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

export const Button = styled.button`
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
`;
