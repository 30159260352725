import React from "react";
import { Container } from "./styles";
import { Testimony } from "./components/testimony";
import { Request } from "./components/request";
import { Identify } from "./components/identify";
import { Candle } from "./components/candle";
import { storageKeys } from "./core/storageKeys";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  const [identification, setIdentification] = React.useState(undefined);

  React.useEffect(() => {
    setIdentification(localStorage.getItem(storageKeys.identification));
  }, []);

  return (
    <>
      <Analytics />
      {!identification ? (
        <Identify />
      ) : (
        <Container>
          <Testimony />
          <Candle />
          <Request />
        </Container>
      )}
    </>
  );
};

export default App;
