export const createTestemony = async (testemony) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/testimonies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(testemony)
    });
    const data = await response.json();
    return data;
}

export const getTestemonies = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/testimonies`);
    const data = await response.json();
    return data;
}

export const createRequest = async (request) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/requests`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    });
    const data = await response.json();
    return data;
}

export const getRequests = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/requests`);
    const data = await response.json();
    return data;
}