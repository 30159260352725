import React from 'react';
import {
  Container,
  InputText,
  Button,
  FooterContainer,
  CardContainer,
  Card,
  Title,
  CardFooter
} from './styles';
import { storageKeys } from '../../core/storageKeys'
import moment from 'moment';
import { AppContext } from '../../core/context';

export const Testimony = () => {
  const [testimony, setTestimony] = React.useState('');
  const user = JSON.parse(localStorage.getItem(storageKeys.identification));
  const { testimonies, addTestemony } = React.useContext(AppContext);

  const handleCreateTestimony = async (testimony) => {
    addTestemony(testimony);
    setTestimony('');
  }

  return <>
    <Container>
      <Title>Testemunhos</Title>
      <CardContainer>
        {
          testimonies.map((card, index) => {
            return <Card key={index}>
              {card.description}
              <CardFooter>{`${card.personName}, ${card.tribe}, ${moment(card.createdAt).format('DD/MM/yyyy HH:mm')}`}</CardFooter>
            </Card>;
          })
        }
      </CardContainer>
      <FooterContainer>
        <InputText id='testimony' rows="5" cols="40" placeholder='Insira seu testemunho aqui! 🙏' value={testimony} onChange={(e) => setTestimony(e.target.value)} />
        <Button type='submit' disabled={testimony.trim().length < 8} onClick={() => {
          handleCreateTestimony({ description: testimony, personName: user.name, tribe: user.tribe });
        }}>Testemunhar</Button>
      </FooterContainer>
    </Container>
  </>
};
