import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Button = styled.button`
  width: 400px;
  height: 80px;
  margin: 15px;
  font-size: 24px;
  background-color: black;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }
`;

export const Img = styled.img`
  display: flex;
`;

export const Flag = styled.img`
  position: absolute;
  height: 200px;
  width: 400px;
  bottom: 20px;
`