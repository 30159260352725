import React from 'react'
import {
  Container,
  Modal,
  InputText,
  Button
} from './styles';
import { storageKeys } from '../../core/storageKeys'
import { AppContext } from '../../core/context';

export const CreateRequest = ({ closeModal }) => {
  const [request, setRequest] = React.useState('');
  const { addRequest } = React.useContext(AppContext);
  const user = JSON.parse(localStorage.getItem(storageKeys.identification));

  const handleCreateRequest = async (request) => {
    addRequest(request);
    closeModal()
  }

  return <Modal>
    <h3>O que você quer pedir?</h3>
    <InputText onChange={(e) => setRequest(e.target.value)} />
    <Button disabled={request.trim().length < 8} onClick={() => {
      handleCreateRequest({ description: request, personName: user.name, tribe: user.tribe });
    }}>🙏 Oremos 🙏</Button>
  </Modal>
};
