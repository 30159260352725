import React from 'react'
import {
  Container,
  IdentificationBox,
  Input,
  ContainerWrapper,
  Button
} from './styles';
import { storageKeys } from "../../core/storageKeys";

export const Identify = () => {
  const [identification, setIdentification] = React.useState({
    name: '',
    tribe: '',
  });

  return <Container>
    <ContainerWrapper>
      <h1>Bem vindo a Vela!</h1>
      <IdentificationBox>
        Qual seu nome?
        <Input onChange={(e) => setIdentification({ ...identification, name: e.target.value })} />
        Qual sua tribo?
        <Input onChange={(e) => setIdentification({ ...identification, tribe: e.target.value })} />
        <Button type='submit' onClick={(e) => {
          e.preventDefault();
          
          localStorage.setItem(storageKeys.identification, JSON.stringify(identification));
          window.location.reload();
        }}>Salvar</Button>
      </IdentificationBox>
    </ContainerWrapper>
  </Container >;
};
