import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid #FFF;
  max-width: 350px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  width: 90%;
  overflow: auto;
`;

export const Card = styled.div`
  display: flex;
  background-color: #FFF;
  color: #000;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 12px;
  padding-top: 10px;
`;

export const Title = styled.h1`
`;