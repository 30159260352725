import React, { useEffect, useState } from 'react';
import { getTestemonies, createTestemony, getRequests, createRequest } from '../services/api';

const context = {
  testimonies: [],
  requests: [],
  addTestemony: (testemonyData) => { },
  addRequest: (requestData) => { },
};

export const AppContext = React.createContext(context);

export function AppContextProvider(props) {
  const [testimonies, setTestimonies] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    (async () => {
      setTestimonies(await getTestemonies());
      setRequests(await getRequests());
    })();
  }, []);

  async function addTestemony(testemonyData) {
    try {
      const response = await createTestemony(testemonyData);
      setTestimonies([response, ...testimonies]);
    } catch (error) {
      console.error(error);
    }
  }

  async function addRequest(requestData) {
    try {
      const response = await createRequest(requestData);
      setRequests([response, ...requests]);
    } catch (error) {
      console.error(error);
    }
  }

  const contextValue = {
    testimonies,
    requests,
    addTestemony,
    addRequest,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
}