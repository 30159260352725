import React from 'react';
import {
  Container,
  CardContainer,
  Card,
  Title,
  CardFooter
} from './styles';
import moment from 'moment';
import { AppContext } from '../../core/context';

export const Request = () => {
  const { requests } = React.useContext(AppContext);

  return <Container>
    <Title>Pedidos</Title>
    <CardContainer>
      {
        requests.map((card, index) => {
          return <Card key={index}>
            {card.description}
            <CardFooter>{`${card.personName}, ${card.tribe}, ${moment(card.createdAt).format('DD/MM/yyyy HH:mm')}`}</CardFooter>
          </Card>;
        })
      }
    </CardContainer>
  </Container>
};
